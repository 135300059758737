import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts/';

const SecondPage = () => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad'
    }
    }
    }}>

     <div>
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <p>
        Music
      </p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default SecondPage
